import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import "./App.scss";
import PIA from "./components/PrivacyImpactAssessment";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path="/" exact component={PIA} />
          <Route path="/:assessmentId" exact component={PIA} />
          {/* <Route path="/privacyimpactassessment" component={PIA} /> */}
        
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
