import React, { useEffect } from "react";
const ImportScript = (props) => {
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  useEffect(() => {
    setScriptLoaded(false)
    document.getElementById("wirewheel").innerHTML = ""
    if(document.getElementById("wirewheel-ppp")) document.getElementById("wirewheel-ppp").remove()
      const script = document.createElement("script");
      script.src = props.src;
      script.dataset.pageId = props.pageId;
      script.id = "wirewheel-ppp";
      script.dataset.jsonSrc = "https://corteva.wirewheel.io";
      script.async = true;
      script.crossOrigin = "anonymous";
  
      document.getElementById("wirewheel").appendChild(script)
  
      script.onload = function () {
        console.log("script loaded");
        const value = window.document.dispatchEvent(
          new Event("DOMContentLoaded", {
            bubbles: true,
          })
        );
        setScriptLoaded(value);
      }
    
  }, [props]);

  useEffect(() => {
    if (scriptLoaded) {
      console.log("event triggered");
      var handle = setInterval(() => {
        if (document.getElementsByClassName("ww-page-wrapper").length) {
          clearInterval(handle);
          var submitBtn = document.getElementsByClassName(
            "ww-self-service__form-submit"
          )[0];

          submitBtn.setAttribute("value", "Begin Assessment");

          submitBtn.addEventListener("click", function () {
            setTimeout(() => submitBtn.disabled = true, 50)
            var showLoader = setInterval(() => {
              if (
                document.getElementsByClassName(
                  "ww-self-service__return-container"
                ).length
              ) {
                clearInterval(showLoader);
                var openRequest = document.getElementsByClassName(
                  "ww-self-service__return-button"
                )[0];
                submitBtn.disabled = false;
                openRequest.innerHTML = "Open Assessment";
              }
              if (
                document.getElementsByClassName("ww-self-service__success-link")
                  .length
              ) {
                var newRequest = document.getElementsByClassName(
                  "ww-self-service__success-link"
                )[0];
                newRequest.style.display = "none";
              }
            }, 100);
          });
        }
      }, 100);
    }
  }, [scriptLoaded]);

  return <div>
    <div style={{textAlign: "center", marginTop: "20px"}}>
    {!scriptLoaded ? <h4>Loading...</h4> : ""}
    </div>
   
    <div id="wirewheel" className="container"></div>
    </div>;
};
export default ImportScript;
