import React, {useState, useEffect} from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
function PrivacyImpactAssessment() {
  const [pageId, setPageId] = useState('')
  const selectStyle = {
    width: "200px"

  }
const selectPageHandler = (pageId) => {
  window.location.replace(`/?id=${pageId}`);
  
}
useEffect(() => {
  let assessmentId;
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  if(params.id) {
    assessmentId = params.id;
  } else {
    setPageId("none")
  }
  if(assessmentId !== "none") setPageId(assessmentId)
}, [])

  return (
    <>
      <SrrHeader heading="Privacy Impact Assessment" />

      <section className="form-section my-3 text-justify">
        <div style={{display: "block", textAlign: "center"}}>
          <p>In what region (APAC, EMEA, LATAM, NA) do the Data Subjects reside for this project/initiative? If more than one region, please choose “Global”.</p>
        <select value={pageId} style={selectStyle} onChange={(e) => selectPageHandler(e.target.value)}>
          <option value="none">Select a Region</option>
          <option value="657b61ce7973ff001ee82f0a">Global</option>
          <option value="657b637a7973ff001ee82f0f">EMEA</option>
          <option value="657b88b98e4477001f4d8dc8">North America</option>
          <option value="657b89598e4477001f4d8dcc">LATAM</option>
          <option value="657b89d98e4477001f4d8dd7">APAC</option>
        </select>
        </div>
       {pageId ?
       
        <ImportScript
          src={`https://wirewheel-p3-public-storage-corteva.s3.us-east-1.amazonaws.com/${pageId}/embedded-ppp.js`}
          // pagId="63ec714a0f3e1f00133559a4"
          pageId={pageId}
        /> : ""
       }
      </section>
    </>
  );
}

export default PrivacyImpactAssessment;
